/* Dark overlay background */
.popUp-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content box */
.popup-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
}

.popup-content h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Close button */
.popup-close {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Input and button styling */
.button-group-popup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.button-group-popup input {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.button-group-popup button {
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #dac6b6;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-group-popup button:hover {
  background-color: #b89e90;
}

/* Popup fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
