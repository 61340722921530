@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");
.cookie-regular {
  font-family: "Cookie", cursive;
  font-weight: 400;
  font-style: normal;
}
.end-logo {
  background-image: url("../src/5.png");
  height: 50%;
  width: 80%;
  background-repeat: no-repeat;
  background-position: center;
}
.header-section {
  width: 100%;
  background-color: #dac6b6;
  background-image: none;
  background-size: center;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 15px 15px;
  padding-top: 30px;
  height: 130px;
  position: fixed;
}
.middle-section {
  background-color: #e8e8e8;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: -5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.h2-thankspage {
  text-align: center;
  position: relative;
  top: 250px;
  font-style: italic;
  font-size: 70px;
}
