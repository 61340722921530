.loading-container {
  background-color: #dac6b6;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease, visibility 1s ease;
}

.loading-container.hidden {
  opacity: 0;
  visibility: hidden;
  height: 0px;
  width: 0px;
}

.loading-image {
  width: 100px;
  height: 100px;
  position: absolute;
  left: -150px;
  top: 50%;
  transform: translateY(-50%);
  animation: move-and-spin 2s cubic-bezier(0.6, 0.04, 0.98, 0.335) forwards;
}

@keyframes move-and-spin {
  0% {
    left: -150px;
    transform: translateY(-50%) rotate(0deg);
  }
  40% {
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    left: 50%;
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    left: 110%;
    transform: translateY(-50%) rotate(360deg);
  }
}
