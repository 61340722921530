.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.max-width-height {
  height: 100%;
  width: 100%;
}
.bg-champagne {
  background-color: #dac6b6;
}
.form-signup {
  background-color: #fff;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.form-signup input[type="text"],
.form-signup input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 0px 20px 20px 0px;
  box-sizing: border-box;
  background-color: #e9e9e9;
}
.form-signup label {
  display: flex;
  flex-direction: row;
}

.form-signup button {
  background-color: #8d1818;
  color: #fff;
  cursor: pointer;
  border-radius: 30px;
  font-weight: bold;
  border: none;
  padding: 10px;
  width: 98%;
  height: 36px;
}

.form-signup button:hover {
  background-color: #dac6b6;
  color: #8d1818;
  font-weight: bold;
}
.footer-signup {
  background-color: #800020 !important;
  position: relative;
  top: -20px;
  z-index: 1;
}
