@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/******** GENERAL ********/

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
body {
  height: 100% !important;
}
.flex-left {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
}
/******** DROPDOWN-POP********/

.dropdown-pop {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #dac6b6;
  flex-wrap: wrap;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
/******** BOUTON CLOSE ********/

.btn-close-pop {
  position: relative;
  left: 43%;
}
.btn-close-style {
  position: relative;
  top: 10px;
  right: 15px;
}

/******** WHITE BLOC ********/

.white-bloc {
  background-color: #fdfcfb;
  width: 86%;
  /*height: 90%;*/
  margin: 5%;
  border-radius: 20px 20px 20px 20px !important;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  box-shadow: 2px 2px 13px 9px rgba(197, 175, 154, 0.88);
}
/******** INPUT CONTAINER ********/

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
}

.wine-name-bloc div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #574e4e75;
  width: 90%;
}
.wine-name-bloc {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.bloc-saveWineName textarea {
  background-color: #fdfcfb;
  color: #574e4ece;
  border: none;
  font-size: 24px;
  text-transform: uppercase;
  font-family: "Montserrat";
  font-style: italic;
  height: auto;
  width: 90%;
  resize: none;
}
.bloc-saveWineName textarea:focus,
.txt-commentaire-2:focus {
  outline: none;
}
.bloc-saveWineName button {
  background-color: #fdfcfb;
  color: #574e4e75;
  border: none;
}
.WineName {
  font-size: 25px;
  margin: 0%;
}
.WineName textarea :focus {
  outline: none;
}

/******** DROPDOWN CONTAINER ********/

.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropdown-list-h3 {
  background-color: #dac6b6;
  margin: 30px 0px 0px 0px;
  color: #574e4e;
  padding: 10px;
  border-radius: 15px;
  width: 86%;
  display: flex;
  justify-content: space-between;
  font-weight: 650;
  z-index: 3;
}
.chevron-position {
  padding: 1px;
}
.select {
  width: 90%;
  color: #333;
}

/*********** BOUCHE ***********/

.bloc-bouche-flex {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-left: 2px solid #dcdcdc;
  border-right: 2px solid #dcdcdc;
  position: relative;
  top: -7px;
}
.txt-commentaire-1 {
  width: 90%;
  height: 100px;
  border: none;
  color: #574e4e;
  font-weight: 200;
  padding-top: 10px;
  border-bottom: 2px solid #dcdcdc;
  margin-bottom: 10px;
  font-family: "Montserrat";
  font-style: italic;
  font-size: 18px;
}

.button-bouche button {
  background-color: #800020;
  color: #fff;
  border: none;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 10px 30px 10px 30px;
}
.button-bouche {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 90%;
  border: 2px solid #dcdcdc;
  border-top: none;
  border-radius: 0 0 20px 20px;
  position: relative;
  top: -8px;
}

.rating-container {
  flex: 0 0 calc(50% - 10px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.rating-container label {
  font-size: 18px;
  margin-bottom: 10px;
  padding-left: 5px;
  color: #574e4e;
  font-family: "Montserrat";
  font-weight: 300;
}

.rating-container input[type="range"] {
  width: calc(
    100% - 20px
  ); /* Subtract the padding added to the rating numbers */
  margin: 0 10px; /* Add margin to align with the rating numbers */
}

.rating-container span {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.circle-style {
  width: 30px;
  height: 30px;
  color: #800020;
  opacity: 1;
  border: 2px solid #800020;
  border-radius: 50%;
  transition: background-color 0.3s, opacity 0.3s;
}

.circle-style:active,
.circle-style:hover {
  background-color: #800020;
  opacity: 1;
}

.circle-container {
  display: flex;
  margin: 0 20px 0 20px;
  gap: 10px;
}

/*********** NEZ***********/

.nez-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 10px;
  width: 90%;
  position: relative;
  top: -22px;
  z-index: 2;
}
.nez-container div {
  position: relative;
  z-index: 50;
}

/*********** NOTATION ***********/

.color-select-container,
.txt-commentaire-2,
.rating-container-2,
.nez-container {
  border: 2px solid #dcdcdc;
  border-top: 0px;
  border-radius: 0px 0px 15px 15px;
  background-color: #fff;
}
.rating-container-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 27px 0 15px 0;
  width: 90%;
  position: relative;
  top: -22px;
  z-index: 2;
}

/*********** COMMENTAIRE ***********/

.txt-commentaire-2 {
  width: 90%;
  height: 100px;
  border: none;
}

.txt-commentaire-2 {
  width: 87%;
  padding-top: 15px;
  position: relative;
  top: -15px;
  border: 2px solid #dcdcdc;
  background-color: #fff;
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  color: #574e4e;
  font-weight: 200;
}
.txt-commentaire-2 {
  padding-left: 10px;
}
/*********** FORMULAIRE D'ENVOIE ***********/

.flex-send-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.send-form {
  background-color: #dac6b6;
  width: 80%;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.send-form input {
  width: 90%;
  height: 36px;
  border-radius: 18px 0 0 18px;
  border: none;
  margin: 10px;
  padding-left: 10px;
  margin: 0;
}
.form-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
}
.btn-form {
  background-color: #800020;
  border-radius: 0 18px 18px 0;
  width: 60px;
  height: 40px;
  border: none;
}
.dropdown-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.dropdown-footer {
  width: 90%;
  color: #534f4e;
  text-align: center;
  padding: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-size: 15px;
}

/*************************************************************/

/* React Select custom styles */
/*
.custom-select__control {
  padding: 10px;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  border-color: #800020;
}

.custom-select__control:hover {
  border-color: #dac6b6;
}

.custom-select__menu {
  background-color: #dac6b6;
  border: 1px solid #dac6b6;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-select__option {
  padding: 10px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.custom-select__option--is-focused {
  background-color: #dac6b6;
}

.custom-select__single-value {
  color: #333;
}

/*
.pencil-edit {
  background-color: transparent;
  border: none;
  color: #000;
}
.check-button {
  background-color: #fdfcfb;
  border: none;
  color: #574e4ece;
}

.card {
  border: none !important;
}
.date-input {
  background-color: white;
  border-radius: 20px;
  border: none;
  width: 100%;
  height: 40px;
  text-align: center;
}
.bloc-date {
  width: 74%;
  position: relative;
  top: 20px;
}
.bloc-date input {
  box-shadow: rgba(120, 119, 119, 0.428) 1px 2px 10px 2px;
}

.rating-numbers {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 5px;
}

.color-label {
  text-align: center;
}


/*
.header-position {
  background-color: #dac6b6;
}*/

/*450 LIGNE*/
