/* ColorSelect.css */

/* Container for the color select component */
.color-select-container {
  padding-top: 22px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 5px 20px 5px;
  font-size: 12px;
}

/* Title styling */
.color-select-title {
  margin: 0;
  padding-bottom: 5px;
  font-size: 18px;
  color: #574e4e;
  font-style: italic;
  font-weight: 200;
}

/* Container for color buttons */
.color-buttons {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px; /* Adjust space between buttons */
  justify-content: space-between;
  width: 95%;
}

/* Color button styling */
.color-button {
  width: 40px; /* Size of the button */
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;
}

.color-select-container {
  width: 88%;
  position: relative;
  top: -18px;
}

.color-button:hover {
  transform: scale(1.1); /* Slightly enlarge button on hover */
}

.color-button.selected {
  border: 3px solid #000; /* Border for selected colors */
}

/* Dot inside the button for selected state */
.color-dot {
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 12px; /* Size of the dot */
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff; /* Border of the dot */
}
